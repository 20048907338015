import React from 'react';

//library import
import LinearProgress from '@mui/material/LinearProgress';

//custom component import
import SingleProject from './SingleProject';

//custom interface import
import { I_ProjectContainer } from '../../../providers/interfaces/interfaces';
import { ReactElement } from 'react-markdown/lib/react-markdown';

const ProjectContainer: React.FunctionComponent<I_ProjectContainer> = (
  props: I_ProjectContainer
) => {
  const mapProjects = (): JSX.Element[] | ReactElement => {
    return props.projectArray.map((element, key) => {
      return (
        <SingleProject
          projectLink={element.projectLink}
          key={key}
          projectName={element.projectName}
        />
      );
    });
  };
  if (props.projectArray.length <= 0) {
    return <LinearProgress color='inherit' />;
  }
  return (
    <>
      <div id='project-container'>
        <div className='container-fluid'>
          <div className='row'>{mapProjects()}</div>
        </div>
      </div>
    </>
  );
};

export default ProjectContainer;
