import React from 'react';
import { useEffect, useState } from 'react';

//css styles import
import './Home.css';

//import libs
import { useParams } from 'react-router-dom';

//custom components import
import Navbar from '../../global-components/Navbar';
import TitleComponent from './Components/Title-component';
import ProjectContainer from './Components/Project-container';
import Footer from '../../global-components/Footer';
import { HomeProvider } from '../../providers/API-providers/Home-provider';
import { LanguageService } from '../../providers/Language-services/Language-service';

//custom interface import
import { I_SingleProject } from '../../providers/interfaces/interfaces';

const homeProvider = new HomeProvider();
const languageService = new LanguageService();

const Home: React.FunctionComponent = () => {
  const [projects, setProjects] = useState<I_SingleProject[]>([]);
  const [description, setDescription] = useState<string>('');
  const params = useParams();
  let path = decodeURIComponent(window.location.pathname);
  path = path.replaceAll('-', ' ');

  useEffect(() => {
    if (path.charAt(path.length - 1) == '/') {
      location.replace(path.slice(-1));
    }
    if (
      params.lang != languageService.getLanguage() &&
      params.lang != undefined &&
      (params.lang == 'en-us' || params.lang == 'cs-cz')
    ) {
      languageService.setLanguage(params.lang);
      location.reload();
    }
    fetchProjects();
    fetchHomePage();
  }, []);

  const fetchHomePage = (): void => {
    if (params.lang == undefined) return;
    homeProvider
      .getMainTitle(params.lang)
      .then((result) => {
        if (!result.ok) throw result;
        return result.json();
      })
      .then((data) => {
        setDescription(data?.Content);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getTitle = (link: string): string => {
    link = link.replaceAll('-', ' ');
    let linkArray = link.split('/');
    linkArray = linkArray.filter((val) => val != '');
    return linkArray[linkArray.length - 1];
  };

  const fetchProjects = (): void => {
    if (params.lang == undefined) return;
    homeProvider
      .fetchProjects(params.lang)
      .then((result) => {
        if (!result.ok) throw result;
        return result.json();
      })
      .then((data) => {
        if (data.TableOfContent == undefined) throw '400 bad data';
        const projectsStringArray = data.TableOfContent[0].SubPage;

        projectsStringArray.forEach((element: any) => {
          setProjects((projects) => [
            ...projects,
            {
              projectName: getTitle(element.Path),
              projectLink: languageService.getLanguage() + element.Path,
            },
          ]);
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <div id='home'>
        <Navbar />
        <TitleComponent title={'Seyfor Docs'} description={description} />
        <ProjectContainer projectArray={projects} />
        <Footer />
      </div>
    </>
  );
};

export default Home;
