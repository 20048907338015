import './Components.css';
import React from 'react';
import SeyforLogo from '../assets/images/logos/solitea-logo';

const Navbar: React.FunctionComponent = () => {
  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-light'>
        <a className='navbar-brand' href='/'>
          <SeyforLogo />
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav mr-auto'>
            <li className='nav-item'>
              <a className='nav-link' href='/' id='docs-link'>
                <div className='nav-link-text'>Docs</div>
                <span className='sr-only'>(current)</span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
