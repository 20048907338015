import React from 'react';
import { useEffect } from 'react';
//styles import
import './App.css';

//lib import
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import $ from 'jquery';

//components import
import Home from './pages/home/Home';
import Wiki from './pages/wiki/Wiki';
import { LanguageService } from './providers/Language-services/Language-service';
import { ThemeService } from './providers/Theme-services/Theme-service';

const languageSerivce = new LanguageService();
const themeService = new ThemeService();

const App: React.FunctionComponent = () => {
  //initial theme setting
  useEffect(() => {
    $('html').addClass(themeService.getTheme());
  }, []);

  const element = (
    <Router>
      <Routes>
        <Route element={<Home />} path='/:lang' />
        <Route element={<Wiki />} path='/:lang/:articlePath/*' />
        <Route
          element={<Navigate to={'/' + languageSerivce.getLanguage()} />}
          path='/'
        />

        <Route
          element={<Navigate to={'/' + languageSerivce.getLanguage()} />}
          path={'/' + languageSerivce.getLanguage() + '/Apps'}
        />
      </Routes>
    </Router>
  );

  return element;
};

export default App;
