import React from 'react';
//lib import
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

//custom components import
import ActionButtons from '../../../global-components/ActionButtons';
import { LanguageService } from '../../../providers/Language-services/Language-service';

//custom interface import
import { I_NavigationBreadcrumbs } from '../../../providers/interfaces/interfaces';

const languageService = new LanguageService();

const NavigationBreadcrumbs: React.FunctionComponent<I_NavigationBreadcrumbs> = (
  props: I_NavigationBreadcrumbs
) => {
  let linkArray: string[];
  let lastItem = '';

  const getLinkArray = (): void => {
    linkArray = props.pageURL.split('/');
    linkArray = linkArray.filter((link) => link != '');
  };

  const createLinks = (): Array<JSX.Element> => {
    getLinkArray();
    let linkBuilder = '/';
    lastItem = linkArray[linkArray.length - 1];
    linkArray.pop();
    linkArray.splice(0, 1);
    return linkArray.map((value, key) => {
      value = value.replace(' ', '-');

      if (key === 0) {
        linkBuilder += value;
      } else {
        linkBuilder += '/' + value;
      }

      return (
        <Link
          underline='hover'
          key={key}
          color='inherit'
          href={'/' + languageService.getLanguage() + linkBuilder}
        >
          {value}
        </Link>
      );
    });
  };

  return (
    <>
      <div id='navigation-breadcrumbs' className='d-flex'>
        <Breadcrumbs
          aria-label='breadcrumb'
          className={
            props.isResources
              ? 'resources-breadcrumbs d-flex cust-breadcrumbs'
              : 'd-flex cust-breadcrumbs'
          }
        >
          {createLinks()}
          <Typography color='text.primary'>{lastItem}</Typography>
        </Breadcrumbs>
        <ActionButtons />
      </div>
    </>
  );
};

export default NavigationBreadcrumbs;
